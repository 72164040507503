import { graphql } from '../generated-gql';

export const queryAdminUserWhitelists = graphql(`
  query AdminUserWhitelists {
    adminUserWhitelists {
      email
      role
      isRegistered
    }
  }
`);
