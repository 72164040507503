import { graphql } from '../generated-gql';

export const queryKiosk = graphql(`
  query Kiosk($kioskId: ID!) {
    kiosk(id: $kioskId) {
      id
      isActive
      taxRate
      maintenanceScreen
      printIncludeLogo
      tablet
      terminalId
      terminalLocationId
      autoCoupon
      cardMaxRating
      productId
      disableGiftCards
      giftCardBlacklist
    }
  }
`);
