import { graphql } from '../generated-gql/gql';

export const queryGiftCardCategories = graphql(`
  query GiftCardCategories {
    giftCardCategories {
      id
      categoryNameSpanish
      categoryName
    }
  }
`);
